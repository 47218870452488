<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button
          v-if="id"
          _key="createUser"
          title="Usuário"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          eventName="createUser"
          :clicked="create"
        />
      </div>
      <div slot="content-main">
        <UserCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import UserCreateUpdate from "../../../components/modules/adm/user/UserCreateUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UsuarioAdicionarModificarView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    UserCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Administração",
        title: "Usuário",
        formName: "userCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "usuarioAdicionar",
      });
      this.removeLoading(["createUser"]);
    },
  },
};
</script>
