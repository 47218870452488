<template>
  <div>
    <Loading v-show="loading" />
    <Rules
      v-if="!loading"
      :rules="rules"
      :userType="userType"
      :urlCreate="'/api/v1/adm/user-rule/create'"
      :urlDelete="`/api/v1/adm/user-rule/delete`"
      :urlCreateDeletePermission="'/api/v1/adm/user-rule/create-delete-permission'"
    />
    <Alert type="warning" v-if="!loading && rules.length == 0">
      <span> É necessário solicitar liberação dos módulos!</span>
    </Alert>
  </div>
</template>

<script>
import Rules from "./Rules.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";

import { mapActions, mapState } from "vuex";

export default {
  name: "UserRule",
  components: { Alert, Toggle, Rules, Loading },
  props: {
    userType: Number,
  },
  data() {
    return {
      id: this.$route.params.id,
      urlGetRulesCompany: "/api/v1/adm/company/get-all-rules",
      urlGetRulesUser: "/api/v1/adm/user-rule/get-all",
      rules: [],
      loading: true,
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
  },
  mounted() {
    this.getCompanyRules();
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getCompanyRules() {
      let params = {
        url: this.urlGetRulesCompany,
        obj: { companyId: this.userLogged.companyId },
      };
      this.getApi(params).then((response) => {
        this.rules = response.content;
        this.getUserRules();
      });
    },
    getUserRules() {
      let params = {
        url: this.urlGetRulesUser,
        obj: { userId: this.id },
      };
      this.getApi(params).then((response) => {
        let self = this;
        response.content.forEach(function (rule) {
          self.hasRule(rule);
        });
        this.loading = false;
      });
    },
    hasRule(userRule) {
      this.rules.forEach(function (companyRule) {
        if (companyRule.ruleId === userRule.ruleId) {
          companyRule.hasRule = true;
          companyRule.permission = userRule.permission == null ? [] : userRule.permission;
        }
      });
    },
  },
};
</script>
